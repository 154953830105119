<template>
  <v-card class="pa-0">
    <v-toolbar width="100%" class="mx-0" color="secondary " dark>
      <v-card-text class="text-h5">Create New Assignment</v-card-text>
      <v-spacer> </v-spacer>
      <v-btn icon @click="$emit('closeDialog')">
        <v-icon large color="red lighten-2">mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-toolbar>
    <v-form v-on:submit.prevent="save" ref="form" class="pa-6">
      <v-text-field
        v-model="name"
        label="Title"
        hide-details="auto"
        :rules="$requiredRules"
      ></v-text-field>
      <v-text-field v-model="details" label="Short Description"></v-text-field>
      <v-row>
        <v-col>
          <v-text-field
            type="number"
            v-model="mark"
            label="Mark"
            :rules="$requiredRules"
          ></v-text-field>
        </v-col>
        <v-col>
          <DateTimeInput
            :show_second="false"
            label="Set Due Date and Time"
            v-model="due_date_time"
          ></DateTimeInput>
        </v-col>
      </v-row>

      <v-file-input
        label="Attachments"
        v-model="attachments"
        prepend-icon=""
        prepend-inner-icon="mdi-attachment"
        accept=".doc,.docx,.pdf,.jpg,.jpeg,.png"
        chips
        show-size
        multiple
        counter
      ></v-file-input>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="blue darken-1" text @click="$emit('closeDialog')">
          Close
        </v-btn> -->
        <v-btn color="secondary" type="submit"> Save </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import DateTimeInput from "../../global/DateTimeInput.vue";
function initialState() {
  return {
    form: "",
    name: "",
    details: "",
    due_date: null,
    start: null,
    due_time: null,
    menu: false,
    menu2: false,
    mark: 0,
    selected_class: {},
    due_date_time: "",
    attachments: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
  };
}
export default {
  props: ["class_id"],
  data: function () {
    return {
      form: "",
      name: "",
      details: "",
      due_date: null,
      start: null,
      due_time: null,
      menu: false,
      menu2: false,
      selected_class: {},
      attachments: [],
      due_date_time: "",
      mark: 0,
      classes: this.$store.state.classes.classes,
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
    };
  },
  emits: ["closeDialog"],
  // props: ["selectedDate"],
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("assignments/add", {
            name: this.name,
            description: this.details,
            attachments: this.attachments,
            room_subject: this.class_id ? this.class_id : this.selected_class.id,
            submission_date_time: this.due_date_time,
            mark: this.mark,
          })
          .then(() => {
            Object.assign(this.$data, initialState());
            alert("Success");
            this.$emit("closeDialog");
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    getSelectText(item) {
      return item.section;
    },
  },
  computed: {
    today() {
      return new Date(new Date() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },
  },
  components: { DateTimeInput },
};
</script>
<style></style>
