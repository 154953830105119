var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-timeline',{attrs:{"align-top":"","dense":""}},[_vm._l((_vm.activities),function(activity,index){return _c('v-timeline-item',{key:index,attrs:{"color":activity.color}},[_c('v-row',[_c('v-col',{staticClass:"px-0"},[_c('v-card',{staticClass:"p-2",attrs:{"outlined":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-card-text',[_vm._v(_vm._s(activity.created_at))]),(activity.type === 'assignment')?_c('v-chip',{staticClass:"mx-2",attrs:{"dark":"","color":"red lighten-2"},domProps:{"textContent":_vm._s(
                  `Submit Before: ${new Date(
                    activity.submission_date_time
                  ).toLocaleString()}`
                )}}):_vm._e()],1),_c('v-col',{staticClass:"text-right"},[_c('v-chip',{staticClass:"mx-1",attrs:{"dark":"","color":activity.color},domProps:{"textContent":_vm._s(activity.type)}}),(activity.mark)?_c('v-chip',{staticClass:"mx-1",attrs:{"dark":"","color":"#95CD41"},domProps:{"textContent":_vm._s(`mark: ${activity.mark}`)}}):_vm._e(),(_vm.owner)?_c('v-btn',{attrs:{"icon":"","to":{
                  name: `${activity.type}s`,
                  params: { id: activity.id },
                }}},[_c('v-icon',[_vm._v("mdi-arrow-top-right")])],1):_vm._e()],1)],1),_c('v-card-title',[_vm._v(_vm._s(activity.name))]),_c('v-card-text',[_vm._v(_vm._s(activity.description))]),_c('v-row',{staticClass:"pa-4",attrs:{"justify":"center"}},_vm._l((activity.files),function(file){return _c('v-col',{key:file.id,staticClass:"ma-2 pa-2 elevation-1",attrs:{"cols":"12","lg":"4","md":"6"}},[_c('Thumbnail',{attrs:{"file":file.file}})],1)}),1),(activity.type == 'assignment' || 'exam')?_c('v-card-actions',[(
                // !activity.is_author &&
                activity.type !== 'note' && !activity.has_submitted
              )?_c('v-file-input',{staticClass:"pr-2",attrs:{"multiple":"","small-chips":"","counter":"","prepend-inner-icon":"mdi-file-upload","prepend-icon":"","hint":"Select single/multiple files","label":activity.type === 'exam'
                  ? 'Submit your Answer'
                  : 'Submit your Assignment',"append-icon":"mdi-check"},on:{"click:append":function($event){activity.type === 'exam'
                  ? _vm.submitAnswer(activity)
                  : _vm.submitAssignment(activity)}},model:{value:(activity.attachments),callback:function ($$v) {_vm.$set(activity, "attachments", $$v)},expression:"activity.attachments"}}):_vm._e(),(
                // !activity.is_author &&
                activity.type !== 'note' && activity.has_submitted
              )?_c('v-card-text',[_vm._v("You Have Already Submitted ")]):_vm._e()],1):_vm._e(),_c('v-list-group',{on:{"click":function($event){return _vm.getComments(activity)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Comments")])]},proxy:true}],null,true)},[_c('v-list-item',[_c('v-text-field',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":() => {
                        _vm.submitComment(activity);
                        activity.comment = '';
                      }}},[_c('v-icon',[_vm._v("mdi-send")])],1)]},proxy:true}],null,true),model:{value:(activity.comment),callback:function ($$v) {_vm.$set(activity, "comment", $$v)},expression:"activity.comment"}})],1),_vm._l((activity.comments),function(comment){return _c('v-list-item',{key:comment.id},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(comment.text)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(comment.user_name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(new Date(comment.created_at).toLocaleString())}})],1)],1)})],2)],1)],1)],1)],1)}),(_vm.activities.length === 0)?_c('v-timeline-item',{attrs:{"color":"red lighten-3"}},[_c('v-card-text',[_vm._v("No activities yet")])],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }