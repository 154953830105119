<template>
  <v-container>
    <v-form @submit.prevent="saveQuiz">
      <v-text-field v-model="quiz.title" label="Quiz Title"></v-text-field>
      <div>
        <div class="picker-row">
      <v-menu v-model="dateMenu" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on }">
          <v-text-field v-model="quiz.date" label="Quiz Date" readonly v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="quiz.date" no-title></v-date-picker>
      </v-menu>

      <v-menu v-model="startTimeMenu" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on }">
          <v-text-field v-model="quiz.start_time" label="Start Time" readonly v-on="on"></v-text-field>
        </template>
        <v-time-picker v-model="quiz.start_time" no-title></v-time-picker>
      </v-menu>

      <v-menu v-model="endTimeMenu" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on }">
          <v-text-field v-model="quiz.end_time" label="End Time" readonly v-on="on"></v-text-field>
        </template>
        <v-time-picker v-model="quiz.end_time" no-title></v-time-picker>
      </v-menu>
    </div>

  
  </div>

      <div
        v-for="(question, questionIndex) in questions"
        :key="questionIndex"
        style="border: 2px solid #90ee90; margin-top: 10px;margin-bottom: 10px; padding: 10px"
      >
        <v-divider></v-divider>
        <v-text-field
          v-model="question.question_text"
          label="Question"
        ></v-text-field>
        <v-text-field v-model="question.mark" label="mark"></v-text-field>

        <v-select
          v-model="question.question_type"
          :items="questionTypes"
          label="Question Type"
        ></v-select>
        <div
          v-if="
            question.question_type === 'Checkbox for Single' || question.question_type === 'Checkbox for Multiple' ||
            question.question_type === 'Dropdown'
          "
        >
          <!-- <v-text-field v-model="newOptionText" label="Option"></v-text-field> -->
          <v-btn @click="addOption(questionIndex)">Add Option</v-btn>

          <v-list-item
            v-for="(option, optionIndex) in question.options"
            :key="optionIndex"
          >
            <v-text-field
              v-model="option.option_text"
              :label="'Option ' + (optionIndex + 1)"
            ></v-text-field>
            <v-btn @click="removeOption(questionIndex, optionIndex)"
              >Remove Option</v-btn
            >
            
            <v-checkbox
              v-model="option.is_correct"
              label="Correct"
            ></v-checkbox>
          </v-list-item>
        </div>
        <v-btn @click="removeQuestion(questionIndex)" color="error">Delete Question</v-btn>

      </div>

      <v-btn @click="addQuestion">Add Question</v-btn>
      <!-- <v-btn @click="delQuestion">Delete Question</v-btn> -->

      <v-btn color="primary" type="submit">Save Quiz</v-btn>
    </v-form>
  </v-container>
</template>
<script>
export default {
    props:["class_id"],

  data() {
    return {
      showFields: false,
      quiz: {
        title: "",
        date: null,
        start_time: null,
        end_time: null,
        rooms:this.class_id,
      },
      dateMenu: false,
      startTimeMenu: false,
      endTimeMenu: false,
      questions: [],
      mark: "",
    
      newOptionText: "",
      questionTypes: ["Checkbox for Single","Checkbox for Multiple", "Dropdown", "Text", "File"],
    };
  },
  methods: {
    addQuestion() {
      this.questions.push({
        question_text: "",
        question_type: "",
        mark: "",
        options: [],
      });
    },
    removeQuestion(index) {
      this.questions.splice(index, 1);
    },
    addOption(questionIndex) {
      this.questions[questionIndex].options.push({
        option_text: "",
      });
      this.newOptionText = "";
    },
    removeOption(questionIndex, optionIndex) {
      this.questions[questionIndex].options.splice(optionIndex, 1);
    },
    saveQuiz() {
      if (!this.quiz.title || !this.quiz.date || !this.quiz.start_time || !this.quiz.end_time || this.questions.length === 0) {
        alert("Please fill in all required fields.");
        return;
      }
      this.$api
        .post("/quizzes/", this.quiz)
        .then((response) => {
          const quizId = response.data.id;
          this.questions.forEach((question) => {
            question.quiz = quizId;
            this.$api
              .post("/questions/", question)
              .then((response) => {
                const questionId = response.data.id;
                question.options.forEach((option) => {
                  option.question = questionId;
                  this.$api
                    .post("/options/", option)
                    .then((response) => {
                      console.log("Option saved:", response.data);
                  
                    })
                    .catch((error) => {
                      console.error("Failed to save option:", error);
                    });
                });
              })
              .catch((error) => {
                console.error("Failed to save question:", error);
              });
              alert("Data saved successfully.");

          });
        })
        .catch((error) => {
          console.error("Failed to save quiz:", error);
        });
    },
  },
};
</script>
<style>
.picker-row {
  display: flex;
  gap: 10px;
}
</style>