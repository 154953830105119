<template>
  <v-card>
    <v-card-title> Update Room Meetings</v-card-title>
    <v-card-subtitle class="px-6">*click on column to update</v-card-subtitle>

    <v-data-table class="mt-4" :headers="headers" :items="meetings">
      <template v-slot:item.meeting.name="props">
        <v-edit-dialog
          :return-value.sync="props.item.meeting.name"
          large
          persistent
          @save="save(props.item)"
        >
          <div>{{ props.item.meeting.name }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Update Name</div>
            <v-text-field
              v-model="props.item.meeting.name"
              label="Edit"
              single-line
              autofocus
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.description="props">
        <v-edit-dialog
          :return-value.sync="props.item.meeting.description"
          large
          persistent
          @save="save(props.item)"
        >
          <div>{{ props.item.meeting.description }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Update Description</div>
            <v-text-field
              v-model="props.item.meeting.description"
              label="Edit"
              single-line
              autofocus
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.meeting.date="props">
        <v-edit-dialog
          :return-value.sync="props.item.date"
          large
          persistent
          @save="save(props.item)"
        >
          <div>{{ props.item.meeting.date }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Update Meeting Date</div>
            <v-date-picker
              v-model="props.item.date"
              label="Edit"
            ></v-date-picker>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.meeting.start_time="props">
        <v-edit-dialog
          :return-value.sync="props.item.meeting.start_time"
          large
          persistent
          @save="save(props.item)"
        >
          <div>{{ props.item.meeting.start_time }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Update Meeting Start Time</div>
            <v-time-picker
              v-model="props.item.meeting.start_time"
              label="Edit"
            ></v-time-picker>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.end_time="props">
        <v-edit-dialog
          :return-value.sync="props.item.meeting.end_time"
          large
          persistent
          @save="save(props.item)"
        >
          <div>{{ props.item.end_time }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Update Meeting End Time</div>

            <v-time-picker v-model="props.item.meeting.end_time" label="Edit">
            </v-time-picker>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="deleteItem(item)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import EditMeeting from "../views/edit/EditMeeting.vue";
export default {
  components: { EditMeeting },
  props: ["class_id"],
  name: "ClassMeetings",
  data() {
    return {
      dialogEditMeeting: false,
      headers: [
        {
          text: "Title",
          value: "meeting.name",
        },
        {
          text: "Description",
          value: "meeting.description",
        },
        {
          text: "Date",
          value: "meeting.date",
        },
        {
          text: "Starts at",
          value: "meeting.start_time",
        },
        {
          text: "Ends at",
          value: "meeting.end_time",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      items: [],
    };
  },
  computed: {
    meetings() {
      return this.items.map((item) => ({
        ...item,
        date: new Date(item.meeting.date),
        starts_at: new Date(`${item.meeting.date} ${item.meeting.start_time}`),
        ends_at: new Date(`${item.meeting.date} ${item.meeting.end_time}`),
        timed: true,
      }));
    },
  },
  mounted() {
    this.loadMeetings();
  },
  methods: {
    loadMeetings() {
      return this.$axios
        .get("/meetings/room-subject-meetings/", {
          params: { room_subject: this.class_id },
        })
        .then((res) => (this.items = res.data.results));
    },
    editItem(item) {
      this.$store.dispatch("meetings/update", item);
    },

    deleteItem({ id }) {
      this.$store.dispatch("meetings/delete", { id });
    },
    save(item) {
      console.log(item);
      this.$store.dispatch("meetings/update", item);
    },
  },
};
</script>

<style>
</style>
