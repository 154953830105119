<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="quizzesData"
      :items-per-page="5"
      hide-default-footer
    >
      <!-- <template v-slot:item.actions="{ item }" v-if="is_teacher">
        <v-btn small color="white" @click="editQuiz(item)">
          <v-icon style="font-size: 24px">mdi-pencil</v-icon>
        </v-btn>
      </template> -->
      <template v-slot:item.date="{ item }">
        {{ moment(item.date).format("Do MMMM YYYY") }},
        {{ moment(item.start_time, "HH:mm:ss").format("LT") }} -
        {{ moment(item.end_time, "HH:mm:ss").format("LT") }}
      </template>

      <template v-slot:item.actions="{ item }" v-if="is_teacher">
        <template v-if="item.is_editquiz">
          <v-btn small color="white" @click="editQuiz(item)">
            <v-icon style="font-size: 24px">mdi-pencil</v-icon>
          </v-btn>
        </template>
      </template>
      <template v-slot:item.actions="{ item }" v-else>
        <template v-if="item.has_answer_button">
          <v-btn small color="white" @click="showQuestions(item.id)">
            Start
          </v-btn>
        </template>
        <template v-else>
          <v-btn small color="white" @click="showAnswer(item.id, item.user)">
            Result
          </v-btn>
        </template>
      </template>
    </v-data-table>

    <v-dialog v-model="showTable" max-width="80%">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>Quiz List </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="showTable = false" class="text-right"
            ><v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-data-table :headers="headers" :items="quizzesData">
          <template v-slot:item.date="{ item }">
            {{ moment(item.date).format("Do MMMM YYYY") }},
            {{ moment(item.start_time, "HH:mm:ss").format("LT") }} -
            {{ moment(item.end_time, "HH:mm:ss").format("LT") }}
          </template>
          <template v-slot:item.actions="{ item }" v-if="is_teacher">
            <!-- <v-btn small color="white" v-if="is_editquiz" @click="editQuiz(item)">
              <v-icon style="font-size: 24px">mdi-pencil</v-icon>
            </v-btn> -->
            <template v-if="item.is_editquiz">
              <v-btn small color="white" @click="editQuiz(item)">
                <v-icon style="font-size: 24px">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <router-link
              :to="`/quiz/${item.id}`"
              tag="v-btn"
              small
              color="white"
              class="ma-2"
              @click="fetchStudentResults(item.id)"
            >
              <v-icon>mdi-call-made</v-icon>
            </router-link>
          </template>
          <template v-slot:item.actions="{ item }" v-else>
            <template v-if="item.has_answer_button">
              <v-btn small color="white" @click="showQuestions(item.id)">
                Start
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                small
                color="white"
                @click="showAnswer(item.id, item.user)"
              >
                Result
              </v-btn>
            </template>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <!-- studentResults Show for Teacher -->
    <v-dialog v-model="dialogforStudent" max-width="500px">
      <v-card>
        <v-card-title>Student Results</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="result in studentResults" :key="result.id">
              <v-list-item-content>
                <v-list-item-title>{{ result.user }}</v-list-item-title>
                <v-list-item-subtitle
                  >Total Mark: {{ result.total_mark }}</v-list-item-subtitle
                >
                <v-list-item-subtitle v-if="result.option_given_answer">
                  Option Given Answer:
                  {{ result.option_given_answer.option_text }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="result.text_answer">
                  Text Answer: {{ result.text_answer }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="result.file_answer">
                  File Answer: {{ result.file_answer }}
                </v-list-item-subtitle>
                <v-btn small color="error" @click="deleteResult(result.id)"
                  >Delete</v-btn
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div style="display: flex; justify-content: center; padding: 10px">
      <v-btn outlined @click="openAddQuizDialog" v-if="is_teacher"
        >Add Quiz</v-btn
      >
      <v-btn outlined class="pa-2 ml-2" @click="viewAll">View All</v-btn>
    </div>
    <v-dialog v-model="showAddDialog" max-width="80%">
      <v-card>
        <v-card-title>Add Quiz</v-card-title>
        <v-card-text>
          <exam-quiz :class_id="class_id"></exam-quiz>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="cancelAdd">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit  -->
    <v-dialog v-model="showEditDialog" max-width="80%">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span>Edit Quiz</span>
          <v-btn color="red" @click="showEditDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form @submit.prevent="saveQuiz">
            <v-text-field
              v-model="editedQuiz.title"
              label="Quiz Title"
            ></v-text-field>
            <div>
              <div class="picker-row">
                <v-menu v-model="date" :close-on-content-click="false" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="editedQuiz.date"
                      label="Quiz Date"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedQuiz.date"
                    no-title
                  ></v-date-picker>
                </v-menu>

                <v-menu
                  v-model="startTimeMenu"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="editedQuiz.start_time"
                      label="Start Time"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="editedQuiz.start_time"
                    no-title
                  ></v-time-picker>
                </v-menu>

                <v-menu
                  v-model="endTimeMenu"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="editedQuiz.end_time"
                      label="End Time"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="editedQuiz.end_time"
                    no-title
                  ></v-time-picker>
                </v-menu>
              </div>
            </div>

            <div
              v-for="(question, questionIndex) in editedQuiz.questions"
              :key="questionIndex"
              style="
                border: 2px solid #90ee90;
                margin-top: 10px;
                margin-bottom: 10px;
                padding: 10px;
              "
            >
              <v-divider></v-divider>
              <v-text-field
                v-model="question.question_text"
                label="Question"
              ></v-text-field>
              <v-text-field v-model="question.mark" label="mark"></v-text-field>

              <v-select
                v-model="question.question_type"
                :items="questionTypes"
                label="Question Type"
              ></v-select>
              <div
                v-if="
                  question.question_type === 'Checkbox for Single' ||
                  question.question_type === 'Checkbox for Multiple' ||
                  question.question_type === 'Dropdown'
                "
              >
                <v-btn @click="addOption(questionIndex)">Add Option</v-btn>

                <v-list-item
                  v-for="(option, optionIndex) in question.options"
                  :key="optionIndex"
                >
                  <v-text-field
                    v-model="option.option_text"
                    :label="'Option ' + (optionIndex + 1)"
                  ></v-text-field>
                  <v-btn @click="removeOption(questionIndex, optionIndex)"
                    >Remove Option</v-btn
                  >
                  <v-checkbox
                    v-model="option.is_correct"
                    label="Correct"
                  ></v-checkbox>
                </v-list-item>
              </div>
              <v-btn @click="removeQuestion(questionIndex)" color="error"
                >Delete Question</v-btn
              >
            </div>
            <v-btn @click="addQuestion">Add Question</v-btn>

            <v-btn color="primary" type="submit">Edit</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- start quiz -->

    <v-dialog v-if="selectedQuiz" v-model="showPopup" max-width="700px">
      <v-card v-if="!showStartButton">
        <v-card-title>{{ selectedQuiz.title }}</v-card-title>
        <v-card-title>Time: {{ timerMinutes }}:{{ timerSeconds }}</v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="12"
                v-for="(question, index) in selectedQuiz.questions"
                :key="question.id"
              >
                <v-card v-if="index === activeQuestionIndex">
                  <v-card-title>{{ question.question_text }}</v-card-title>
                  <v-card-text>
                    <template
                      v-if="question.question_type === 'Checkbox for Single'"
                    >
                      <v-radio-group v-model="selectedOptions[question.id]">
                        <v-radio
                          v-for="option in question.options"
                          :key="option.id"
                          :label="option.option_text"
                          :value="option.id"
                        ></v-radio>
                      </v-radio-group>
                    </template>
                    <template
                      v-else-if="
                        question.question_type === 'Checkbox for Multiple'
                      "
                    >
                      <v-checkbox-group v-model="selectedOptions[question.id]">
                        <v-checkbox
                          v-for="option in question.options"
                          v-model="selectedOptions[question.id]"
                          :key="option.id"
                          :label="option.option_text"
                          :value="option.id"
                        ></v-checkbox>
                      </v-checkbox-group>
                    </template>
                    <template v-else-if="question.question_type === 'Dropdown'">
                      <v-select
                        v-model="selectedOptions[question.id]"
                        :items="question.options"
                        item-text="option_text"
                        item-value="id"
                        label="Select an option"
                      ></v-select>
                    </template>
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        margin: auto;
                      "
                    >
                      <h2 style="border: 2px solid blue; padding: 2px">
                        Mark: {{ question.mark }}
                      </h2>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions
          style="background-color: rgb(216, 231, 215); width: 100%"
        >
          <v-btn
            color="primary"
            @click="previousQuestion"
            :disabled="activeQuestionIndex === 0"
          >
            Previous
          </v-btn>
          <v-btn
            color="primary"
            @click="nextQuestion"
            :disabled="
              activeQuestionIndex === selectedQuiz.questions.length - 1
            "
          >
            Next
          </v-btn>
          <v-btn
            color="primary"
            @click="submitQuiz"
            v-if="activeQuestionIndex === selectedQuiz.questions.length - 1"
          >
            Submit
          </v-btn>
          <v-spacer></v-spacer>

          <v-btn color="red" style="float: right" @click="closePopup"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>

      <v-card v-if="showStartButton">
        <v-card-title> Quiz Title :{{ selectedQuiz.title }}</v-card-title>

        <v-card-actions>
          <v-card-text>
            <h3>Total Marks : {{ selectedQuiz.total_marks }}</h3>
            <h4>
              Start-Time :{{
                moment().format(
                  " Do MMMM YYYY, h:mm a",
                  selectedQuiz.start_time
                )
              }}
            </h4>
            <h4>
              End-Time :{{
                moment().format(" Do MMMM YYYY, h:mm a", selectedQuiz.end_time)
              }}
            </h4>
          </v-card-text>

          <v-btn color="primary" @click="startQuiz">Start Quiz</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Results -->
    <v-dialog v-model="showAnswerResults" max-width="500px">
      <v-card>
        <v-card-title>Quiz Results</v-card-title>

        <v-card-text>
          <v-card v-for="answer in getResult" :key="answer.id">
            <div
              style="border: 1px solid rgb(11, 190, 77); margin-bottom: 10px"
            >
              <v-card-title>Quiz: {{ answer.quiz_name }}</v-card-title>
              <div style="margin-left: 13px">
                Student Name: {{ answer.user }}
              </div>

              <div style="margin-left: 13px; margin-bottom: 5px">
                Total achieved : {{ answer.total_marks }} out of
                {{ answer.total_question_marks }}
              </div>
            </div>
            <v-card-text>
              <div v-for="(question, index) in answer.questions" :key="index">
                <div>
                  Question: {{ question.question_text }}
                  <span style="float: right">
                    Mark : {{ question.question_mark }}
                  </span>
                </div>

                <ul>
                  <li
                    v-for="(option, optionIndex) in question.options"
                    :key="optionIndex"
                    :class="{
                      green:
                        option.is_correct &&
                        isOptionCorrect(option, answer.selected_options),
                      red:
                        !option.is_correct &&
                        isOptionSelected(option, answer.selected_options),
                    }"
                  >
                    {{ option.option_text }}
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="showAnswerResults = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="showResults" style="margin-left: 20px; margin-left: 10px">
      <h3 style="margin-bottom: 40px; margin-top: 20px">Quiz Results</h3>
      <h2 style="margin-bottom: 40px; margin-top: 20px; font-size: 17px">
        Total Achieved: {{ totalMarks }}/{{ totalMarksOutOf }}
      </h2>

      <div
        v-for="(question, index) in selectedQuiz.questions"
        :key="index"
        style="margin-top: 10px"
      >
        <p style="font-weight: bold">
          <span v-if="isAnswerCorrect(question.id)" style="color: green"
            >&#10004;</span
          >
          <span v-else style="color: red">&#10006;</span>
          Question: {{ question.question_text }}
        </p>
        <ul>
          <li
            v-for="option in question.options"
            :key="option.id"
            :style="{ color: getOptionColor(question.id, option.id) }"
          >
            {{ option.option_text }}
          </li>
        </ul>
      </div>
      <p>Total Marks: {{ totalMarks }}</p>
    </div>
  </div>
</template>

<script>
import ExamQuiz from "./ExamQuiz.vue";

export default {
  components: { ExamQuiz },
  props: ["class_id", "is_teacher"],

  watch: {
    selectedQuiz: {
      immediate: true,
      handler() {
        this.editedQuiz = { ...this.selectedQuiz };
      },
    },
  },
  data() {
    return {
      showStartButton: true,

      showEditDialog: false,
      editedQuiz: {},
      questionTypes: [
        { text: "Checkbox for Single", value: "Checkbox for Single" },
        { text: "Checkbox for Multiple", value: "Checkbox for Multiple" },
        { text: "Dropdown", value: "Dropdown" },
        { text: "Text", value: "Text" },
        { text: "File", value: "File" },
      ],
      dialogforStudent: false,
      headers: [
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Date /time",
          value: "date",
        },
        // {
        //   text: "Start Time",
        //   value: "start_time",
        // },
        // {
        //   text: "End Time",
        //   value: "end_time",
        // },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "right",
        },
      ],
      dateMenu: false,
      startTimeMenu: false,
      date: "",
      endTimeMenu: false,
      quizzesData: [],
      selectedQuiz: null,
      isEditMode: false,
      activeQuestionIndex: 0,
      selectedOptions: {},
      showPopup: false,
      totalMarks: 0,
      totalMarksOutOf: 0,
      showResults: false,
      correctAnswers: {},
      givenAnswers: {},
      timerMinutes: 0,
      timerSeconds: 0,
      showAddDialog: false,
      rooms: "",
      showTable: false,
      answerData: [],
      showAnswerResults: false,

      studentResults: [],
      getResult: [],
      answerQuizList: [],
    };
  },

  mounted() {
    this.fetchQuiz();
    this.fetchAnswer();
  },

  methods: {
    startQuiz() {
      this.showStartButton = false;
      this.startTimer();
    },

    fetchStudentResults(quizId) {
      this.$api
        .get(`/answersquiz/?quiz=${quizId}`)
        .then((response) => {
          this.answerQuizList = response.data.results;
          console.log("resultis " + this.answerQuizList);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    deleteResult(resultId) {
      this.$api
        .delete(`/answers/${resultId}/`)
        .then((response) => {
          this.studentResults = this.studentResults.filter(
            (result) => result.id !== resultId
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
    isOptionCorrect(option, selectedOptions) {
      return selectedOptions.includes(option.option_text);
    },
    isOptionSelected(option, selectedOptions) {
      return selectedOptions.includes(option.option_text);
    },
    fetchAnswer() {
      this.$api.get("/answersquiz/").then((response) => {
        this.answerData = response.data.results;
      });
    },

    showAnswer(quizId) {
      this.getResult = this.answerData.filter(
        (answer) => answer.quiz === quizId
      );

      this.showAnswerResults = true;
      this.fetchAnswer();
    },
    viewAll() {
      this.showTable = true;
    },
    openAddQuizDialog() {
      this.selectedQuiz = null;
      this.showAddDialog = true;
    },
    fetchQuiz() {
      this.$api.get("/quizzes/").then((response) => {
        this.quizzesData = response.data.results;
      });
    },
    openQuizDialog(item) {
      this.selectedQuiz = item;
      this.showEditDialog = true;
    },
    // saveQuiz() {
    //   this.closeDialog();
    // },
    closeDialog() {
      this.showEditDialog = false;
      this.selectedQuiz = null;
    },
    editQuiz(item) {
      this.selectedQuiz = item;
      this.showEditDialog = true;

      this.fetchQuiz();
    },
    removeQuestion(index) {
      const question = this.editedQuiz.questions[index];
      const questionId = question.id;

      if (questionId) {
        this.$api
          .delete(`/questions/${questionId}/`)
          .then(() => {
            this.editedQuiz.questions.splice(index, 1);
          })
          .catch((error) => {
            console.error("Failed to delete question:", error);
          });
      } else {
        this.editedQuiz.questions.splice(index, 1);
      }
    },
    addQuestion() {
      const newQuestion = {
        question_text: "",
        question_type: "",
        mark: 0,
        options: [],
      };

      this.editedQuiz.questions.push(newQuestion);

      this.$api
        .post("/questions/", newQuestion)
        .then((response) => {
          const createdQuestion = response.data;
          this.editedQuiz.questions.push(createdQuestion);
        })
        .catch((error) => {
          console.error("Failed to create question:", error);
        });
    },

    addOption(questionIndex) {
      const question = this.editedQuiz.questions[questionIndex];
      const questionId = question.id || null;
      const newOption = {
        option_text: "",
        is_correct: false,
        question: questionId,
      };

      this.editedQuiz.questions[questionIndex].options.push(newOption);

      this.$api
        .post("/options/", newOption)
        .then((response) => {
          const createdOption = response.data;
          question.options.push(createdOption);
        })
        .catch((error) => {
          console.error("Failed to create option:", error);
        });
    },

    removeOption(questionIndex, optionIndex) {
      const option =
        this.editedQuiz.questions[questionIndex].options[optionIndex];
      const optionId = option.id;

      if (optionId) {
        this.$api
          .delete(`/options/${optionId}/`)
          .then(() => {
            this.editedQuiz.questions[questionIndex].options.splice(
              optionIndex,
              1
            );
          })
          .catch((error) => {
            console.error("Failed to delete option:", error);
          });
      } else {
        this.editedQuiz.questions[questionIndex].options.splice(optionIndex, 1);
      }
    },
    saveQuiz() {
      const updatedQuiz = {
        id: this.editedQuiz.id,
        title: this.editedQuiz.title,
        date: this.editedQuiz.date,
        start_time: this.editedQuiz.start_time,
        end_time: this.editedQuiz.end_time,
        rooms: this.class_id,
      };

      this.$api.put(`/quizzes/${updatedQuiz.id}/`, updatedQuiz).then(() => {
        const questionPromises = this.editedQuiz.questions.map((question) => {
          if (question.id) {
            return this.$api.put(`/questions/${question.id}/`, {
              question_text: question.question_text,
              question_type: question.question_type,
              mark: question.mark,
              quiz: updatedQuiz.id,
            });
          } else {
            return this.$api
              .post("/questions/", {
                question_text: question.question_text,
                question_type: question.question_type,
                mark: question.mark,
                quiz: updatedQuiz.id,
              })
              .then((response) => {
                const createdQuestion = response.data;
                this.editedQuiz.questions.push(createdQuestion);
                this.fetchQuiz();
              });
          }
        });

        Promise.all(questionPromises).then(() => {
          const optionPromises = this.editedQuiz.questions.flatMap((question) =>
            question.options.map((option) => {
              if (option.id) {
                return this.$api.put(`/options/${option.id}/`, option);
              } else {
                return this.$api.post("/options/", option).then((response) => {
                  const createdOption = response.data;
                  question.options.push(createdOption);
                });
              }
            })
          );

          Promise.all(optionPromises).then(() => {
            this.showEditDialog = false;
          });
        });
      });
    },
    cancelEdit() {
      this.showEditDialog = false;
    },
    cancelAdd() {
      this.showAddDialog = false;
      this.fetchQuiz();
    },
    showQuestions(quizId) {
      this.selectedQuiz = this.quizzesData.find((quiz) => quiz.id === quizId);
      this.selectedOptions = {};
      this.showPopup = true;
      // this.startTimer();
    },

    previousQuestion() {
      if (this.activeQuestionIndex > 0) {
        this.activeQuestionIndex--;
      }
    },
    nextQuestion() {
      if (this.activeQuestionIndex < this.selectedQuiz.questions.length - 1) {
        this.activeQuestionIndex++;
      }
    },

    fetchQuizList() {
      this.$api.get("/quizzes/").then((response) => {
        this.quizzesData = response.data.results;
      });
    },

    startTimer() {
      setInterval(() => {
        this.timerSeconds++;
        if (this.timerSeconds === 60) {
          this.timerMinutes++;
          this.timerSeconds = 0;
        }
      }, 1000);
    },
    getOptionColor(questionId, optionId) {
      const question = this.selectedQuiz.questions.find(
        (question) => question.id === questionId
      );
      const correctOption = question.options.find(
        (option) => option.is_correct
      );
      const givenOption = question.options.find(
        (option) => option.id === optionId
      );
      const isCorrectAnswer = correctOption && correctOption.id === optionId;
      const isGivenAnswer =
        givenOption && givenOption.id === this.selectedOptions[questionId];

      if (isCorrectAnswer) {
        return "#9acc9b";
      }
      if (isGivenAnswer) {
        return "#dd878a";
      } else {
        return "black";
      }
    },

    isAnswerCorrect(questionId) {
      const correctAnswer = this.correctAnswers[questionId];
      const givenAnswer = this.givenAnswers[questionId];
      return correctAnswer === givenAnswer;
    },

    submitQuiz() {
      const payload = {
        quiz: this.selectedQuiz.id,
        selected_option: [],
        text_answer: null,
        file_answer: null,
        is_submit: true,
      };

      for (const [questionId, selectedOptionId] of Object.entries(
        this.selectedOptions
      )) {
        if (Array.isArray(selectedOptionId)) {
          payload.selected_option.push(...selectedOptionId);
        } else {
          payload.selected_option.push(selectedOptionId);
        }
      }
      this.$api
        .post("/answers/", payload)
        .then((response) => {
          this.selectedOptions = {};
          this.closePopup();
          this.showResults = true;
        })
        .then(() => {
          this.fetchQuiz();
        })
        .catch((error) => {
          console.error("Failed to submit answer:", error);
          alert("You have already submitted an answer for this quiz.");
        });
    },
    closePopup() {
      this.showPopup = false;
    },
  },
};
</script>
