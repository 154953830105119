<template>
  <v-card>
    <v-toolbar dark color="third"
      ><v-toolbar-title>At a glance</v-toolbar-title></v-toolbar
    >
    <v-tabs color="light-yellow" right>
      <v-tab>Notes</v-tab>
      <!-- <v-tab>Links</v-tab> -->
      <v-tab>Assignments</v-tab>
      <v-tab>Exams</v-tab>
      <v-tab>Quiz</v-tab>

      <v-tab>Recordings</v-tab>


      <v-tab-item
        ><TabsNotes :class_id="class_id" :is_teacher="is_teacher"></TabsNotes
      ></v-tab-item>
      <!-- <v-tab-item>
        <TabsLinks :class_id="class_id" :is_teacher="is_teacher"></TabsLinks>
      </v-tab-item> -->
      <v-tab-item
        ><tabs-assignments
          :class_id="class_id"
          :is_teacher="is_teacher"
        ></tabs-assignments
      ></v-tab-item>

      <v-tab-item
        ><tabs-exams :class_id="class_id" :is_teacher="is_teacher"></tabs-exams
      ></v-tab-item>
     
      <v-tab-item
        ><quiz-teacher :class_id="class_id" :is_teacher="is_teacher"></quiz-teacher
      ></v-tab-item>
      <v-tab-item>
        <tabs-recordings :class_id="class_id"></tabs-recordings>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>

import TabsAssignments from "./TabsAssignments.vue";
import TabsExams from "./TabsExams.vue";
import TabsNotes from "./TabsNotes.vue";
import TabsLinks from "./TabsLinks.vue";
import TabsRecordings from "./TabsRecordings.vue";
import QuizTeacher from '../../pages/organization/exam/QuizTeacher.vue';

export default {
  components: {
    TabsAssignments,
    TabsNotes,
    TabsExams,
    TabsLinks,
    TabsRecordings,
    QuizTeacher,
  },
  props: ["class_id", "is_teacher"],
};
</script>
