<template>
  <v-container>
    <v-list three-line>
      <template v-for="(item, index) in items">
        <v-list-item :key="item.id">
          <v-list-item-content>
            <v-list-item-title>{{ item.meeting.name }}</v-list-item-title>
            <v-list-item-subtitle>{{
              item.meeting.description
            }}</v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ moment(item.created_at).format("hh:mm:ss A, DD MMM, YYYY") }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-dialog v-model="dialog" width="1000" height="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" outlined color="primary">
                  Watch
                </v-btn>
              </template>

              <v-card class="py-0" dark width="100%" height="100%">
                <v-card-title class="text-h5 lighten-2">
                  {{ item.meeting.name }}
                </v-card-title>
                <video
                  controls
                  preload="auto"
                  width="100%"
                  height="100%"
                  disablePictureInPicture
                  controlsList="nodownload"
                >
                  <source :src="item.file" type="video/mp4" />
                </video>
              </v-card>
            </v-dialog>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="index"></v-divider>
      </template>
    </v-list>
  </v-container>
</template>

<script>
export default {
  props: ["class_id"],
  data: () => ({
    items: [],
    pagination: {},
  }),
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$axios
        .get("/meetings/room-subject-meeting-recordings/", {
          params: {
            room_subject: this.class_id,
            page_size: 5,
          },
        })
        .then((res) => {
          const { results, ...pagination } = res.data;
          this.items = results;
          this.pagination = pagination;
        });
    },
  },
};
</script>

<style>
</style>